import { useEffect, useRef } from "react"

const BrowserLayout = ({ children }) => {
  const resizeTimeout = useRef(null)

  useEffect(() => {
    const resizeAnimationStop = () => {
      document.body.classList.add("resize-animation-stop")

      clearTimeout(resizeTimeout.current)

      resizeTimeout.current = setTimeout(() => {
        document.body.classList.remove("resize-animation-stop")
      }, 200)
    }

    window.addEventListener("resize", resizeAnimationStop)

    return () => {
      window.removeEventListener("resize", resizeAnimationStop)
    }
  }, [])

  return children
}

export default BrowserLayout

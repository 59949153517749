exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-about-us-js": () => import("./../../../src/pages/en/about-us.js" /* webpackChunkName: "component---src-pages-en-about-us-js" */),
  "component---src-pages-en-carrier-zone-js": () => import("./../../../src/pages/en/carrier-zone.js" /* webpackChunkName: "component---src-pages-en-carrier-zone-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-location-js": () => import("./../../../src/pages/en/location.js" /* webpackChunkName: "component---src-pages-en-location-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("./../../../src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-en-services-js": () => import("./../../../src/pages/en/services.js" /* webpackChunkName: "component---src-pages-en-services-js" */),
  "component---src-pages-en-team-js": () => import("./../../../src/pages/en/team.js" /* webpackChunkName: "component---src-pages-en-team-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lokalizacja-js": () => import("./../../../src/pages/lokalizacja.js" /* webpackChunkName: "component---src-pages-lokalizacja-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-strefa-przewoznika-js": () => import("./../../../src/pages/strefa-przewoznika.js" /* webpackChunkName: "component---src-pages-strefa-przewoznika-js" */),
  "component---src-pages-ua-dlya-pereviznyka-js": () => import("./../../../src/pages/ua/dlya-pereviznyka.js" /* webpackChunkName: "component---src-pages-ua-dlya-pereviznyka-js" */),
  "component---src-pages-ua-index-js": () => import("./../../../src/pages/ua/index.js" /* webpackChunkName: "component---src-pages-ua-index-js" */),
  "component---src-pages-ua-komanda-js": () => import("./../../../src/pages/ua/komanda.js" /* webpackChunkName: "component---src-pages-ua-komanda-js" */),
  "component---src-pages-ua-mistse-roztashuvannya-js": () => import("./../../../src/pages/ua/mistse-roztashuvannya.js" /* webpackChunkName: "component---src-pages-ua-mistse-roztashuvannya-js" */),
  "component---src-pages-ua-polityka-konfidentsiynosti-js": () => import("./../../../src/pages/ua/polityka-konfidentsiynosti.js" /* webpackChunkName: "component---src-pages-ua-polityka-konfidentsiynosti-js" */),
  "component---src-pages-ua-posluhy-js": () => import("./../../../src/pages/ua/posluhy.js" /* webpackChunkName: "component---src-pages-ua-posluhy-js" */),
  "component---src-pages-ua-pro-nas-js": () => import("./../../../src/pages/ua/pro-nas.js" /* webpackChunkName: "component---src-pages-ua-pro-nas-js" */),
  "component---src-pages-uslugi-js": () => import("./../../../src/pages/uslugi.js" /* webpackChunkName: "component---src-pages-uslugi-js" */),
  "component---src-pages-zespol-js": () => import("./../../../src/pages/zespol.js" /* webpackChunkName: "component---src-pages-zespol-js" */)
}

